.page {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
  color: #fff;
}

.page-container {
  width: 100%;
  height: 100%;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.higher-contrast {
  background-image: none !important;
  background-color: #000 !important;
  color: #fff !important;
}

.high-contrast-img {
  opacity: 0;
}

.lighter-bkgd {
  background-image: none !important;
  background-color: #fff !important;
  color: #000 !important;
}

.greyscale {
  filter: grayscale(100%);
}

/* relative to height of page container */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 4;
  background-color: #102640;
  height: 13rem;
  width: 100%;
  display: grid;
  grid-template-rows: min-content min-content;
  align-content: center;
  gap: 2.5%;
  color: #fff;
}

.page-one {
  background-image: url("../img/pageone.jpg");
  grid-template-rows: min-content 1fr;
}

.page-two {
  background-image: url("../img/pagetwo.jpg");
}

.page-three {
  background-color: #d9dde4;
}

.logo-stripe-container {
  position: absolute;
}

.special-stripe {
  position: relative;
}

.logo-stripe {
  height: 5.4rem;
  position: absolute;
  z-index: 1;
  bottom: 0%;
  left: 48%;
  transform: translate(0%, 48%);
}

.footer .logo-stripe {
  bottom: 100%;
  z-index: -1;
}

.navigation {
  background-color: #102640;
  width: 100%;
  height: 18%;
  align-self: start;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation div {
  width: 15%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.navigation-img {
  width: 22rem;
}

.navigation-img.phone {
  display: none;
}

.ph-list {
  display: none;
}

.navigation-list {
  display: flex;
  list-style: none;
  gap: 1.2rem;
}

.navigation-item {
  white-space: nowrap;
  cursor: pointer;
  color: #fff !important;
}

.nav-line {
  color: #d21e46;
}

.page-one .page-text {
  top: 25%;
  transform: translate(0%, -25%);
}

.page-two .page-text {
  background-color: rgb(255, 255, 255, 0.95);
}

.form-text {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  white-space: nowrap;
  gap: 1.8rem;
  margin: 0 auto;
  width: 75%;
  /* font-size */
  font-size: 2.4rem;
}

.form-text hr {
  display: inline-block;
  height: 0.1rem;
  border: 0;
  border-top: 0.1px solid #d21e46;
  margin: 0.75em auto;
  padding: 0;
  width: 100%;
}

.form {
  width: 75%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1.8rem;
}

.form input {
  padding: 0.5rem;
  color: #000;
  font-size: 1.9rem;
  width: 100%;
  border-radius: 0;
}

.form ::placeholder {
  color: #102640;
  opacity: 1; /* Firefox */
  font-family: "Assistant", sans-serif;
  /* font-size */
  font-size: 1.9rem;
}

.btn-subscribe {
  background-color: #d21e46;
  font-size: 1.8rem;
  color: #fff;
  border: none;
}

.section-accessibility {
  position: fixed;
  top: 30%;
  left: 0;
  color: #102640;
  display: flex;
  z-index: 10;
}

.section-accessibility .accessibility-icon {
  position: absolute;
  right: -48%;
  top: 0;
  transform: translate(-48%, 0%);
  background-color: #102640;
  color: #d9dde4;
  font-size: 4.8rem;
  cursor: pointer;
}

.section-accessibility .accessibility-slide {
  transform: translateX(-15rem);
  transition: 0.3s all ease;
  background-color: #fff;
  background-color: #102640;
  color: #fff;
  width: 15rem;
  padding: 3% 5%;
}

.section-accessibility .accessibility-slide.open {
  transform: translateX(0%);
}

.section-accessibility h3 {
  font-size: 1.9rem;
  padding: 2% 0;
}

.section-accessibility .list {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  list-style: none;
  font-size: 1.6rem;
}

.section-accessibility .list-item {
  display: flex;
  align-items: center;
  gap: 5%;
  cursor: pointer;
}
/* PAGES - SPECIFIC */
/* PAGE ONE */
.page-one .page-text {
  position: relative;
  top: 35%;
  transform: translate(0%, -35%);
  margin: 0 auto;
  width: 85%;
  height: 75%;
}

.page-one .big-ideas {
  display: flex;
  align-items: end;
  justify-content: center;

  gap: 15%;
  position: relative;
}

.page-one .big-ideas .logo-stripe {
  position: absolute;
  left: 38%;
  top: -50%;
  z-index: -1;
  height: 100%;
}

.page-one .big-ideas p {
  display: flex;
  flex-direction: column;
  line-height: 0.788;

  /* fonsize */
  font-size: 7.2rem;
}

.page-one .logo-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.page-one .logo-container p {
  /* fonsize */
  font-size: 2.8rem;
}

.page-one .logo-container img {
  position: absolute;
  bottom: 30%;
  left: 0;
  transform: translate(0%, 0%);
  z-index: -1;
  width: 100%;
}

.page-one .details-ideas {
  padding-top: 3%;
  width: 75%;
  margin: 0 auto;
  text-align: center;
}

.page-one .statement {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 1.8rem;
  white-space: nowrap;
  height: min-content;
  /* fontsize */
  font-size: 3.2rem;
}

.page-one .statement hr {
  display: inline-block;
  height: 0.1rem;
  border: 0;
  border-top: 0.1px solid #fff;
  margin: 0.75em auto;
  padding: 0;
  width: 100%;
}

.page-one .details-ideas .paragraph {
  padding: 1% 0;
  width: 75%;
  margin: 0 auto;
}

.page-one .list {
  display: flex;
  list-style: none;
  gap: 1.5%;
  margin: 0 auto;
  width: fit-content;
  /* fontsize */
  font-size: 2.2rem;
}

.page-one .list-item {
  display: flex;
  align-items: center;
  gap: 1%;
  white-space: nowrap;
}

.page-one .list span {
  width: 1.2rem;
  height: 1.2rem;
  background-color: #d21e46;
}

.page-one .end-statement {
  padding-bottom: 2%;
  /* fontsize */
  font-size: 2.2rem;
}

/* PAGE TWO */
.page-two {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-two .page-text {
  color: #102640;
  width: 75%;
  height: fit-content;
  padding: 2% 3% 3% 3%;
}

.page-two .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 85%;
  margin: 0 auto;
}
.page-two .text h2 {
  padding-bottom: 0.2%;
  display: flex;
  gap: 1%;
  align-items: center;
  margin-bottom: 0.5%;
  position: relative;
  align-self: start;
  margin: 0 auto;
}

.page-two .title {
  width: min-content !important;
  font-size: 5.4rem !important;
}

.page-two .text p {
  width: 100%;
  /* font-size */
  font-size: 1.9rem;
}

.page-two .blue-logo {
  width: 25rem;
  padding-top: 2%;
}

.page-two .chunked-paragraph {
  padding-bottom: 3%;
}

.page-two .part {
  padding-bottom: 3%;
}

.page-two .text h3,
.page-three .text-container h3 {
  padding-bottom: 0.2%;
  /* font-size */
  font-size: 3.2rem;
}

.page-two .text h3 {
  /* font-size */
  font-size: 3.3rem;
}

.page-two .text p:last-child,
.page-three .text-container p:last-child {
  padding-bottom: 0;
}

.btn-link-website {
  width: 75%;
  background-color: #102640;
  color: #fff;
  cursor: pointer;
  margin: 0% auto 3% auto;
  padding: 1.4rem 3.2rem;
  border-radius: 4.8rem;
  transition: all 0.3s;
}

.btn-link-website:link,
.btn-link-website:visited {
  text-decoration: none;
  color: #fff;
}

.btn-link-website:hover,
.btn-link-website:active {
  background-color: #0c1e33;
}

.btn-link-website .phone,
.btn-link-website .mini-phone {
  display: none;
}

/* PAGE THREE */
.page-three {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}

.page-three .page-text {
  display: flex;
  gap: 2%;
  color: #102640;
  height: fit-content;
  width: 75%;
  padding-bottom: 5%;
}

.page-three .logo-container {
  position: relative;
  height: 30rem;
}

.page-three .logo-text {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 1;
  width: fit-content;
  display: flex;
  flex-direction: column;
  line-height: 0.788;
  /* font-size */
  font-size: 8.6rem;
}

.page-three .stripe {
  height: 100%;
}

.page-three .text-container {
  display: flex;
  flex-direction: column;
}

.page-three .title-container {
  line-height: 0.9;
  padding-bottom: 0.6%;
}

.protective-padding {
  padding-bottom: 5%;
}

.copyright {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.5% 1%;
  color: #102640;
  margin-bottom: 13rem;
  display: flex;
  gap: 1.2rem;
}

.copyright-phone {
  padding: 1%;
  display: none;
}

.copyright-list {
  display: flex;
  gap: 1.2rem;
  margin: 0 auto;
}

.copyright p {
  font-size: 1.5rem !important;
}

.padding-top {
  padding-top: 2%;
}

/* PAGE TERMS */
.page.terms {
  background-color: #fff;
  color: #102640 !important;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
}

.page-container .copyright-div {
  overflow: scroll !important;
  padding: 5% 15% 5% 15%;
  height: 100%;
}

.page-container .copyright-div h2 {
  font-size: 2.1rem;
  color: #102640;
  padding: 1% 0;
  text-align: center;
}
.page-container .copyright-div h3 {
  font-size: 1.8rem;
  padding: 1.5% 0;
}

.page-container .copyright-div p {
  font-size: 1.8rem;
  padding: 1% 0;
}

.page-terms .copyright-terms {
  background-color: #102640;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1% 2%;
  display: flex;
  gap: 1.2rem;
  font-size: 1.6rem;
}

.copyright p,
.copyright-terms p {
  cursor: pointer;
}

.copyright-list p {
  cursor: pointer;
}

.highlightHref {
  text-decoration: underline !important;
}

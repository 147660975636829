.page {
  color: #fff;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: relative;
}

.page-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}

.higher-contrast {
  color: #fff !important;
  background-color: #000 !important;
  background-image: none !important;
}

.high-contrast-img {
  opacity: 0;
}

.lighter-bkgd {
  color: #000 !important;
  background-color: #fff !important;
  background-image: none !important;
}

.greyscale {
  filter: grayscale();
}

.footer {
  z-index: 4;
  color: #fff;
  background-color: #102640;
  grid-template-rows: min-content min-content;
  align-content: center;
  gap: 2.5%;
  width: 100%;
  height: 13rem;
  display: grid;
  position: fixed;
  bottom: 0;
  left: 0;
}

.page-one {
  background-image: url("pageone.ede1c9cf.jpg");
  grid-template-rows: min-content 1fr;
}

.page-two {
  background-image: url("pagetwo.8186fa21.jpg");
}

.page-three {
  background-color: #d9dde4;
}

.logo-stripe-container {
  position: absolute;
}

.special-stripe {
  position: relative;
}

.logo-stripe {
  z-index: 1;
  height: 5.4rem;
  position: absolute;
  bottom: 0%;
  left: 48%;
  transform: translate(0%, 48%);
}

.footer .logo-stripe {
  z-index: -1;
  bottom: 100%;
}

.navigation {
  background-color: #102640;
  justify-content: center;
  align-self: start;
  align-items: center;
  width: 100%;
  height: 18%;
  display: flex;
}

.navigation div {
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 1rem;
  width: 15%;
  display: flex;
}

.navigation-img {
  width: 22rem;
}

.navigation-img.phone, .ph-list {
  display: none;
}

.navigation-list {
  gap: 1.2rem;
  list-style: none;
  display: flex;
}

.navigation-item {
  white-space: nowrap;
  cursor: pointer;
  color: #fff !important;
}

.nav-line {
  color: #d21e46;
}

.page-one .page-text {
  top: 25%;
  transform: translate(0%, -25%);
}

.page-two .page-text {
  background-color: #fffffff2;
}

.form-text {
  white-space: nowrap;
  grid-template-columns: 1fr min-content 1fr;
  gap: 1.8rem;
  width: 75%;
  margin: 0 auto;
  font-size: 2.4rem;
  display: grid;
}

.form-text hr {
  border: 0;
  border-top: .1px solid #d21e46;
  width: 100%;
  height: .1rem;
  margin: .75em auto;
  padding: 0;
  display: inline-block;
}

.form {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1.8rem;
  width: 75%;
  margin: 0 auto;
  display: grid;
}

.form input {
  color: #000;
  border-radius: 0;
  width: 100%;
  padding: .5rem;
  font-size: 1.9rem;
}

.form ::placeholder {
  color: #102640;
  opacity: 1;
  font-family: Assistant, sans-serif;
  font-size: 1.9rem;
}

.btn-subscribe {
  color: #fff;
  background-color: #d21e46;
  border: none;
  font-size: 1.8rem;
}

.section-accessibility {
  color: #102640;
  z-index: 10;
  display: flex;
  position: fixed;
  top: 30%;
  left: 0;
}

.section-accessibility .accessibility-icon {
  color: #d9dde4;
  cursor: pointer;
  background-color: #102640;
  font-size: 4.8rem;
  position: absolute;
  top: 0;
  right: -48%;
  transform: translate(-48%);
}

.section-accessibility .accessibility-slide {
  color: #fff;
  background-color: #102640;
  width: 15rem;
  padding: 3% 5%;
  transition: all .3s;
  transform: translateX(-15rem);
}

.section-accessibility .accessibility-slide.open {
  transform: translateX(0%);
}

.section-accessibility h3 {
  padding: 2% 0;
  font-size: 1.9rem;
}

.section-accessibility .list {
  flex-direction: column;
  gap: 1.2rem;
  font-size: 1.6rem;
  list-style: none;
  display: flex;
}

.section-accessibility .list-item {
  cursor: pointer;
  align-items: center;
  gap: 5%;
  display: flex;
}

.page-one .page-text {
  width: 85%;
  height: 75%;
  margin: 0 auto;
  position: relative;
  top: 35%;
  transform: translate(0%, -35%);
}

.page-one .big-ideas {
  justify-content: center;
  align-items: end;
  gap: 15%;
  display: flex;
  position: relative;
}

.page-one .big-ideas .logo-stripe {
  z-index: -1;
  height: 100%;
  position: absolute;
  top: -50%;
  left: 38%;
}

.page-one .big-ideas p {
  flex-direction: column;
  font-size: 7.2rem;
  line-height: .788;
  display: flex;
}

.page-one .logo-container {
  flex-direction: column;
  display: flex;
  position: relative;
}

.page-one .logo-container p {
  font-size: 2.8rem;
}

.page-one .logo-container img {
  z-index: -1;
  width: 100%;
  position: absolute;
  bottom: 30%;
  left: 0;
  transform: translate(0%);
}

.page-one .details-ideas {
  text-align: center;
  width: 75%;
  margin: 0 auto;
  padding-top: 3%;
}

.page-one .statement {
  white-space: nowrap;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 1.8rem;
  height: min-content;
  font-size: 3.2rem;
  display: grid;
}

.page-one .statement hr {
  border: 0;
  border-top: .1px solid #fff;
  width: 100%;
  height: .1rem;
  margin: .75em auto;
  padding: 0;
  display: inline-block;
}

.page-one .details-ideas .paragraph {
  width: 75%;
  margin: 0 auto;
  padding: 1% 0;
}

.page-one .list {
  gap: 1.5%;
  width: fit-content;
  margin: 0 auto;
  font-size: 2.2rem;
  list-style: none;
  display: flex;
}

.page-one .list-item {
  white-space: nowrap;
  align-items: center;
  gap: 1%;
  display: flex;
}

.page-one .list span {
  background-color: #d21e46;
  width: 1.2rem;
  height: 1.2rem;
}

.page-one .end-statement {
  padding-bottom: 2%;
  font-size: 2.2rem;
}

.page-two {
  justify-content: center;
  align-items: center;
  display: flex;
}

.page-two .page-text {
  color: #102640;
  width: 75%;
  height: fit-content;
  padding: 2% 3% 3%;
}

.page-two .text {
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 85%;
  margin: 0 auto;
  display: flex;
}

.page-two .text h2 {
  align-self: start;
  align-items: center;
  gap: 1%;
  margin: 0 auto;
  padding-bottom: .2%;
  display: flex;
  position: relative;
}

.page-two .title {
  width: min-content !important;
  font-size: 5.4rem !important;
}

.page-two .text p {
  width: 100%;
  font-size: 1.9rem;
}

.page-two .blue-logo {
  width: 25rem;
  padding-top: 2%;
}

.page-two .chunked-paragraph, .page-two .part {
  padding-bottom: 3%;
}

.page-two .text h3, .page-three .text-container h3 {
  padding-bottom: .2%;
  font-size: 3.2rem;
}

.page-two .text h3 {
  font-size: 3.3rem;
}

.page-two .text p:last-child, .page-three .text-container p:last-child {
  padding-bottom: 0;
}

.btn-link-website {
  color: #fff;
  cursor: pointer;
  background-color: #102640;
  border-radius: 4.8rem;
  width: 75%;
  margin: 0% auto 3%;
  padding: 1.4rem 3.2rem;
  transition: all .3s;
}

.btn-link-website:link, .btn-link-website:visited {
  color: #fff;
  text-decoration: none;
}

.btn-link-website:hover, .btn-link-website:active {
  background-color: #0c1e33;
}

.btn-link-website .phone, .btn-link-website .mini-phone {
  display: none;
}

.page-three {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow-y: hidden;
}

.page-three .page-text {
  color: #102640;
  gap: 2%;
  width: 75%;
  height: fit-content;
  padding-bottom: 5%;
  display: flex;
}

.page-three .logo-container {
  height: 30rem;
  position: relative;
}

.page-three .logo-text {
  z-index: 1;
  flex-direction: column;
  width: fit-content;
  font-size: 8.6rem;
  line-height: .788;
  display: flex;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.page-three .stripe {
  height: 100%;
}

.page-three .text-container {
  flex-direction: column;
  display: flex;
}

.page-three .title-container {
  padding-bottom: .6%;
  line-height: .9;
}

.protective-padding {
  padding-bottom: 5%;
}

.copyright {
  color: #102640;
  gap: 1.2rem;
  margin-bottom: 13rem;
  padding: .5% 1%;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.copyright-phone {
  padding: 1%;
  display: none;
}

.copyright-list {
  gap: 1.2rem;
  margin: 0 auto;
  display: flex;
}

.copyright p {
  font-size: 1.5rem !important;
}

.padding-top {
  padding-top: 2%;
}

.page.terms {
  z-index: 10;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  color: #102640 !important;
}

.page-container .copyright-div {
  height: 100%;
  padding: 5% 15%;
  overflow: scroll !important;
}

.page-container .copyright-div h2 {
  color: #102640;
  text-align: center;
  padding: 1% 0;
  font-size: 2.1rem;
}

.page-container .copyright-div h3 {
  padding: 1.5% 0;
  font-size: 1.8rem;
}

.page-container .copyright-div p {
  padding: 1% 0;
  font-size: 1.8rem;
}

.page-terms .copyright-terms {
  color: #fff;
  background-color: #102640;
  gap: 1.2rem;
  padding: 1% 2%;
  font-size: 1.6rem;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.copyright p, .copyright-terms p, .copyright-list p {
  cursor: pointer;
}

.highlightHref {
  text-decoration: underline !important;
}
/*# sourceMappingURL=home.3228eac3.css.map */
